import request from '../utils/request'
import baseUrl from './baseUrl'
export const setPetDay = (data) => request({
    url: baseUrl + '/petDay/modify',
    method: 'POST',
    data
  })
  export const getPetDay = (data) => request({
    url: baseUrl + '/petDay/select',
    method: 'POST',
    data
  })
  export const petList = (data) => request({
    url: baseUrl + '/petDayRight/selectForBack',
    method: 'POST',
    data
  })
  export const petListAdd = (data) => request({
    url: baseUrl + '/petDayRight/add',
    method: 'POST',
    data
  })
  export const petListDel = (data) => request({
    url: baseUrl + '/petDayRight/deleteById',
    method: 'POST',
    data
  })
  export const petListSet = (data) => request({
    url: baseUrl + '/petDayRight/modify',
    method: 'POST',
    data
  })