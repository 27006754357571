<template>
    <div>
         <el-breadcrumb separator="/">
        <el-breadcrumb-item>宠粉日设置</el-breadcrumb-item>
      </el-breadcrumb>
      
      <el-form label-position="right" label-width="150px" :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" style="width:500px;margin-top:20px">
        <el-form-item label="宠粉日">
          <el-select v-model="ruleForm.value" multiple placeholder="请选择" size='medium ' style="width:200px">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        
        <!--V3新增👇 -->
         <el-form-item label="是否同时使用优惠券">
           <el-radio-group v-model="isCoupon">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </template>
  
  <script>
  import {setPetDay,getPetDay} from "../../../api/petPowder.js";
  export default {
    data() {
      return {
          ruleForm:{
              value:[],
              
              oldUser:'',
          },
          isCoupon:0,
          options:[],
          rules: {
            value: [
              { required: true, message: '请选择宠粉日日期', trigger: 'change' }
            ],
            textarea: [
              { required: true, message: '请填写产品使用时间', trigger: 'change' }
            ]
          }
      };
    },
    methods:{
      oldUserInput(value){
      var reg = /^[0-9]*[1-9][0-9]*$/;
        if (!reg.test(value)) {
           this.$message({
            type: "error",
            message: "请输入整数!",
          });
          this.ruleForm.oldUser = ''
          return false
        }
      },
       getCountDays() {
            var curDate = new Date();
          /* 获取当前月份 */
            var curMonth = curDate.getMonth();
          /*  生成实际的月份: 由于curMonth会比实际月份小1, 故需加1 */
          curDate.setMonth(curMonth + 1);
          /* 将日期设置为0, 这里为什么要这样设置, 我不知道原因, 这是从网上学来的 */
          curDate.setDate(0);
          /* 返回当月的天数 */
            return curDate.getDate();
            
    },
  async submitForm() {
      const parma={
          dayDate:(this.ruleForm.value).toString(),
          isCoupon:this.isCoupon,
          //oldUser:this.ruleForm.oldUser
          // useTime:this.ruleForm.textarea
      }
      const {data} = await setPetDay(parma)
      if(data.code==0){
           this.$message({
              type:'success',
              message:'设置成功'
          })
      }else{
           this.$message.error('设置失败')
          }
         
          // this.$refs[ruleForm].validate((valid) => {
          //   if (valid) {
          // const {data} = await userTime(ruleForm)
          // console.log(data)
          //   } else {
          //     console.log('error submit!!');
          //     return false;
          //   }
          // });
        },
    },
    created(){
        let day=this.getCountDays()
       for(let i=1;i<=day;i++){
          this.options.push(
              {
                  value:i,
                  label:i
              }
          )
       }
       getPetDay().then(res=>{
          if(res.data.code==0){
          this.isCoupon = res.data.data.isCoupon
          this.ruleForm.value=(res.data.data.userDay).split(',')
          // this.ruleForm.textarea=res.data.data.useTime
          }
      })
    }
  };
  </script>
  
  <style scoped>
   .el-breadcrumb {
      height: 35px;
      border-bottom: 1px solid rgb(192, 191, 191);
    }
  .el-select>.el-input{
      width:400px
  }
  </style>